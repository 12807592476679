import { BaseUser } from '../User';

class OnboardingUser extends BaseUser {
	get spokenLangs(): string[][] {
		return this.user.spokenLangs
			? this.user.spokenLangs
					.sort()
					.map((l) => l.split(':'))
					.filter((l) => !/\d/u.test(l[0]))
			: [];
	}

	set spokenLangs(langs) {
		if (langs && Array.isArray(langs)) {
			this.user = Object.assign(this.user, { spokenLangs: langs });
		}
	}
}

const User = new OnboardingUser();

export { User };
